import flatpickr from 'flatpickr';
import FlatpickrLanguages from 'flatpickr/dist/l10n';

document.addEventListener("turbolinks:load", function () {
  flatpickr('.datetimepicker', {
    enableTime: true,
    dateFormat: 'Y-m-d H:i',
    allowInput: true,
    locale: { ...FlatpickrLanguages.zh_tw },
    onOpen(selectedDates, _dateStr, flatpickrInstance) {
      if (selectedDates.length === 0) {
        flatpickrInstance.setDate(new Date());
      }
    },
  });

  flatpickr('.datetimepickerOnlyDate', {
    locale: { ...FlatpickrLanguages.zh_tw },
    format: 'LT',
  });
});
